import classNames from 'classnames';
import DatePicker from 'react-date-picker';
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import calendar from '../../assets/images/calendar.png';
import styles from './FormDateInput.module.css';
import FormError from './FormError.jsx';

const FormDateInput = (props) => {
	const minDate = props.minDate ? props.minDate : new Date();
    const maxDate = props.maxDate ? props.maxDate : null;

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<label className={styles.label}>
				{props.label}
				{props.required && (<span className={styles.asterisk}>*</span>)}
			</label>
			<div className={styles.inputContainer}>
				<DatePicker className={styles.input} placeholder={props.placeholder} style={props.inputWidth && {width: props.inputWidth}} value={props.value} onChange={props.update}
                            format="M/d/y" calendarIcon={null} clearIcon={null} monthPlaceholder="mm" dayPlaceholder="dd" yearPlaceholder="yyyy" minDate={minDate}
                            maxDate={maxDate}/>
				<img className={styles.calendar} src={calendar} alt="Calendar"/>
				{
					props.error && (
						<FormError error={props.error}/>
					)
				}
			</div>
		</div>
	)
};

export default FormDateInput;
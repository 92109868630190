const getOrganizationApi = async (organizationId) => {
    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/organization/' + organizationId, options);
};

export { getOrganizationApi };
import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import styles from './FormLastFour.module.css';
import FormError from './FormError.jsx';

const FormMoneyInput = (props) => {
	const inputClasses = classNames(styles.input, {
		[styles.error]: props.error
	});
	const inputRefs = useRef([]);
	const [lastFour, setLastFour] = useState({
		one: '',
		two: '',
		three: '',
		four: ''
	});

	const onInputFocus = (event) => {
		event.target.select();
	};

    const onInputKeyUp = (event, inputNumber) => {
        if(event.key === 'Backspace' && inputNumber){
            inputRefs.current[inputNumber - 2].focus();
        }
    };

	useEffect(() => {
		props.update(lastFour.one + lastFour.two + lastFour.three + lastFour.four);
	}, [lastFour]);

	useEffect(() => {
		if(lastFour.one.length === 1){
			inputRefs.current[1].focus();
		}
	}, [lastFour.one]);

	useEffect(() => {
		if(lastFour.two.length === 1){
			inputRefs.current[2].focus();
		}
	}, [lastFour.two]);

	useEffect(() => {
		if(lastFour.three.length === 1){
			inputRefs.current[3].focus();
		}
	}, [lastFour.three]);

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<label className={styles.label}>
				{props.label}
				{props.required && (<span className={styles.asterisk}>*</span>)}
			</label>
			<div className={styles.inputContainer}>
				<input className={inputClasses} type="text" pattern="\d*" maxLength="1" placeholder="X" ref={(ref) => inputRefs.current.push(ref)} value={lastFour.one}
					   onChange={(event) => setLastFour({...lastFour, one: event.target.value})}/>
				<input className={inputClasses} type="text" pattern="\d*" maxLength="1" placeholder="X" ref={(ref) => inputRefs.current.push(ref)} value={lastFour.two}
					   onChange={(event) => setLastFour({...lastFour, two: event.target.value})} onFocus={onInputFocus} onKeyUp={(event) => onInputKeyUp(event, 2)}/>
				<input className={inputClasses} type="text" pattern="\d*" maxLength="1" placeholder="X" ref={(ref) => inputRefs.current.push(ref)} value={lastFour.three}
					   onChange={(event) => setLastFour({...lastFour, three: event.target.value})} onFocus={onInputFocus} onKeyUp={(event) => onInputKeyUp(event, 3)}/>
				<input className={inputClasses} type="text" pattern="\d*" maxLength="1" placeholder="X" ref={(ref) => inputRefs.current.push(ref)} value={lastFour.four}
					   onChange={(event) => setLastFour({...lastFour, four: event.target.value})} onFocus={onInputFocus} onKeyUp={(event) => onInputKeyUp(event, 4)}/>
				{
					props.error && (
						<FormError error={props.error}/>
					)
				}
			</div>
		</div>
	)
};

export default FormMoneyInput;
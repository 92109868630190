import { useState } from "react";
import styles from './OrganizationUrl.module.css';
import copy from "../../assets/images/copy.png";
import checkmarkGray from '../../assets/images/checkmark-gray.png';

const OrganizationUrl = (props) => {
    const [copyStatus, setCopyStatus] = useState(false);

    const copyUrl = () => {
        navigator.clipboard.writeText(process.env.REACT_APP_FRONT_END_DOMAIN + '/organization/' + props.organizationId);
        setCopyStatus('Copied!')
    };

    return (
        <div className={props.className}>
            <label className={styles.label}>
                Link
            </label>
            <div className={styles.urlRow}>
                <span className={styles.url}>
                    {process.env.REACT_APP_FRONT_END_DOMAIN}/organization/{props.organizationId}
                </span>
                {
                    copyStatus === false && (
                        <img className={styles.copy} src={copy} alt="Copy" title="Copy link" onClick={copyUrl}/>
                    )
                }
                {
                    copyStatus !== false && (
                        <span className={styles.copyStatus}>
                            <img className={styles.copyStatusIcon} src={checkmarkGray} alt="Checkmark"/>
                            <span className={styles.copyStatusText} onClick={copyUrl}>
                                {copyStatus}
                            </span>
                        </span>
                    )
                }
            </div>
        </div>
    );
};

export default OrganizationUrl;
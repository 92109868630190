import React from 'react';
import ReactDOM from 'react-dom/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Redirect all www traffic to the root domain
if(window.location.host.startsWith("www")){
	window.location = window.location.protocol + "//" + window.location.host.slice(4) + window.location.pathname;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
Bugsnag.start({
    releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
    apiKey: process.env.REACT_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginReact()]
})
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
root.render(
    <ErrorBoundary>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import classNames from 'classnames';
import money from '../../assets/images/money.png';
import styles from './FormMoneyInput.module.css';
import FormError from './FormError.jsx';

const FormMoneyInput = (props) => {
	const inputClasses = classNames(styles.input, {
		[styles.error]: props.error
	});

	return (
		<div className={classNames(styles.container, props.className)} style={props.style}>
			<label className={styles.label}>
				{props.label}
				{props.required && (<span className={styles.asterisk}>*</span>)}
			</label>
			<div className={styles.inputContainer}>
				<input className={inputClasses} type="number" pattern="\d*" min="1" placeholder={props.placeholder} style={props.inputWidth && {width: props.inputWidth}}
					   value={props.value} onChange={props.update}/>
				<img className={styles.money} src={money} alt="Money"/>
				{
					props.error && (
						<FormError error={props.error}/>
					)
				}
			</div>
		</div>
	)
};

export default FormMoneyInput;